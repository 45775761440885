import Cookie from 'js-cookie';
import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';

import { Cookies } from '@/domains/core/httpClient/cookies';

const FlagsOverrideBanner = dynamic(
  async () => (await import('./FlagsOverrideBanner')).FlagsOverrideBanner,
);

export const FlagsOverrideChecker: FunctionComponent = () => {
  /*TODO: this was a call to 'useFeatureFlags' replaced with the value of the flag/abtest. Consider removing this call when you modify the file.*/
  const [enabled] = [true];
  const shouldDisplayBanner =
    typeof window !== 'undefined' &&
    enabled &&
    Cookie.get(Cookies.MM_OVERRIDE) !== undefined;

  return shouldDisplayBanner ? <FlagsOverrideBanner /> : null;
};

FlagsOverrideChecker.displayName = 'FlagsOverrideChecker';
