import { useMemo, useSyncExternalStore } from 'react';

import { BREAKPOINTS } from '@/domains/core/tamagoshiTailwind/tokens/breakpoints';

type MediaQuery = `(${string}:${string})`;

/** List threshold media queries for each breakpoint */
const MIN_WIDTHS = (
  Object.keys(BREAKPOINTS) as (keyof typeof BREAKPOINTS)[]
).reduce(
  (accumulator, breakpoint) => ({
    ...accumulator,
    [breakpoint]: `(min-width: ${BREAKPOINTS[breakpoint]})`,
  }),
  {} as Record<keyof typeof BREAKPOINTS, MediaQuery>,
);

export type MediaQueries = keyof typeof MIN_WIDTHS;

const mediaQuerySubscription = (
  query: MediaQuery,
  serverValue: boolean,
): {
  getSnapshot: () => boolean;
  subscribe: (onChange: () => void) => () => void;
} => {
  const media =
    typeof window === 'undefined' ? undefined : window.matchMedia(query);

  return {
    getSnapshot() {
      return media?.matches ?? serverValue;
    },
    subscribe(onChange) {
      media?.addEventListener('change', onChange);

      return () => {
        media?.removeEventListener('change', onChange);
      };
    },
  };
};

/**
 * Return whether the current viewport matches the provided query.
 * Matches greater or equal to the provided breakpoint token.
 */
export const useMatchMedia = (
  mediaQueryString: MediaQueries,
  serverValue: boolean = false,
) => {
  const { getSnapshot, subscribe } = useMemo(
    () => mediaQuerySubscription(MIN_WIDTHS[mediaQueryString], serverValue),
    [mediaQueryString, serverValue],
  );
  const matches = useSyncExternalStore(
    subscribe,
    getSnapshot,
    () => serverValue,
  );

  return matches;
};
