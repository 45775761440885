import { useState } from 'react';

import { useIsomorphicLayoutEffect } from '@/core/ui/hooks/useIsomorphicLayoutEffect';

/**
 * @deprecated use useMatchMedia in src/domains/core/ui/hooks/useMatchMedia.ts instead
 */
export const useMatchMedia = (
  mediaQueryString: string,
  initialState = false,
) => {
  const [state, setState] = useState(initialState);

  useIsomorphicLayoutEffect(() => {
    if (!window?.matchMedia) {
      return;
    }

    const mediaQueryList = window.matchMedia(mediaQueryString);

    const updateState = () => {
      setState(mediaQueryList.matches);
    };
    updateState();

    if (mediaQueryList.addEventListener !== undefined) {
      mediaQueryList.addEventListener('change', updateState);
    } else {
      mediaQueryList.addListener(updateState);
    }

    return () => {
      if (mediaQueryList.removeEventListener !== undefined) {
        mediaQueryList.removeEventListener('change', updateState);
      } else {
        mediaQueryList.removeListener(updateState);
      }
    };
  }, [mediaQueryString]);

  return state;
};
