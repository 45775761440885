import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';

import { HttpStatusCode } from '@/domains/core/httpClient/HttpStatusCode';

const Error404 = dynamic<React.PropsWithChildren<unknown>>(() =>
  import('@/domains/core/page/components/Error404/Error404').then(
    (mod) => mod.Error404,
  ),
);

const Error500 = dynamic<React.PropsWithChildren<unknown>>(() =>
  import('@/domains/core/page/components/Error50x/Error500').then(
    (mod) => mod.Error500,
  ),
);

const Error504 = dynamic<React.PropsWithChildren<unknown>>(() =>
  import('@/domains/core/page/components/Error50x/Error504').then(
    (mod) => mod.Error504,
  ),
);

interface Props {
  statusCode?: HttpStatusCode;
}

export const BaseErrors: FunctionComponent<React.PropsWithChildren<Props>> = ({
  statusCode,
  children,
}) => {
  if (statusCode === HttpStatusCode.NOT_FOUND) {
    return <Error404 />;
  }

  if (statusCode === HttpStatusCode.INTERNAL_ERROR) {
    return <Error500 />;
  }

  if (statusCode === HttpStatusCode.GATEWAY_TIMEOUT_ERROR) {
    return <Error504 />;
  }

  return <>{children}</>;
};
