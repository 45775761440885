export const FONT_FAMILIES = [
  'Open Sans',
  'mm-main',
  'Tahoma',
  'Arial',
  'sans-serif',
] as const satisfies string[];

export const FONT_WEIGHTS = {
  regular: '400',
  semibold: '600',
  bold: '700',
} as const;

export const FONT_SCALES = [
  'body1',
  'body2',
  'small',
  'tiny',
  'title1',
  'title2',
  'title3',
  'title4',
] as const satisfies string[];

export const LINE_HEIGHTS = {
  title1: '38px',
  title2: '30px',
  title3: '26px',
  title4: '24px',
  body1: '22px',
  body2: '20px',
  small: '18px',
  tiny: '16px',
} as const satisfies Record<(typeof FONT_SCALES)[number], string>;

export const FONT_SIZES = {
  title1: '32px',
  title2: '24px',
  title3: '20px',
  title4: '18px',
  body1: '16px',
  body2: '14px',
  small: '12px',
  tiny: '10px',
} as const satisfies Record<(typeof FONT_SCALES)[number], string>;
