import { type AriaAttributes } from 'react';

/**
 * A throbber for blocking interactions where the user has to wait.
 *
 * See the related [Figma][figma] for more details.
 *
 * [figma]: https://www.figma.com/design/KAiRlBccr6bFYmKby9wQrR?node-id=17762-14863
 */
export const Loader = (props: AriaAttributes) => (
  <div
    aria-busy="true"
    aria-live="polite"
    aria-valuemin={0}
    aria-valuemax={100}
    // NOTE Shift content with about as much padding as the bounce translation
    //      in order to oscillate both up and down around the X axis.
    className="flex gap-xs pb-[5px] *:size-[7px] *:rounded-full
      *:bg-foundation-quinary"
    role="progressbar"
    {...props}
  >
    <div className="animate-oscillate" />
    <div className="animate-oscillate [animation-delay:100ms]" />
    <div className="animate-oscillate [animation-delay:200ms]" />
  </div>
);
