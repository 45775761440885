import {
  forwardRef,
  type ButtonHTMLAttributes,
  type ForwardedRef,
} from 'react';

import { Loader } from '@/core/tamagoshiTailwind/components/Loader/Loader';
import { TamagoshiIconComponent } from '@/core/tamagoshiTailwind/interfaces';
import { twMerge } from '@/core/tamagoshiTailwind/util/twMerge';

type BaseProps = {
  /** Additional classes to be applied to the button */
  className?: string;
  /** Whether the button is disabled */
  disabled?: boolean;
  /**
   * Whether the button should hug against the content.
   * Has no effect for link buttons and icon buttons which are always fit.
   */
  fitContent?: boolean;
  /** Size of the button */
  size?: 'L' | 'M';
  /** Type of button based */
  type?: 'button' | 'submit';
  /** Variant of the button */
  variant: 'link' | 'primary' | 'secondary' | 'tertiary';
} & Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'title'>;

type IconOnlyButtonProps = BaseProps & {
  /** Accessible label for the root element */
  accessibilityLabel: string;
  /** Icon component to be displayed in the button */
  icon: TamagoshiIconComponent;
  loading?: never;
  showIconRight?: never;
  text?: never;
};

type TextButtonProps = BaseProps & {
  accessibilityLabel?: never;
  /** Optional icon component to be displayed in the button */
  icon?: TamagoshiIconComponent;
  /** Whether the button is in a loading state */
  loading?: boolean;
  /** Whether the icon should be displayed on the right or left */
  showIconRight?: boolean;
  /** Text to be displayed in the button */
  text: string;
};

export type ButtonProps = TextButtonProps | IconOnlyButtonProps;

/**
 * A general-purpose button implementing the Design System.
 *
 * Your label should be short and use the imperative tone. If the action behind
 * the button might take time, use the loading property.
 *
 * Technically the button _can_ be loading and disabled although it should not
 * happen as per the design system guidelines. Still the properties are not
 * incompatible for easier usage and provided as a fallback.
 *
 * The button supports the `link` variant in order to _look_ like a link. It is
 * not a link and is not made for navigation inside or outside of the
 * application. To render a link that looks like a button in the DOM, you must
 * use `NavigationButton`.
 *
 * See the related [Figma](figma) for more details.
 *
 * [figma]: https://www.figma.com/design/KAiRlBccr6bFYmKby9wQrR?node-id=15902-17958
 */
export const Button = forwardRef(
  (
    {
      accessibilityLabel,
      className,
      disabled,
      fitContent,
      icon: Icon,
      loading,
      showIconRight,
      size = 'L',
      text,
      type = 'button',
      variant,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button
      aria-label={accessibilityLabel}
      className={twMerge(
        `relative flex items-center justify-center gap-xs rounded-card text-left
        font-semibold disabled:pointer-events-none`,
        fitContent || variant === 'link' || (!text && Icon)
          ? // NOTE Link buttons and icon buttons are always fit
            'w-fit'
          : 'w-full',
        loading && variant !== 'link' && '[&>.text]:invisible',
        showIconRight ? 'flex-row-reverse' : 'flex-row',
        size === 'L' ? 'text-body1' : 'text-body2',
        variant === 'link'
          ? 'underline hover:text-info-primary'
          : size === 'L'
            ? 'h-[48px] px-m'
            : 'h-[40px] px-s',
        variant === 'primary' &&
          `bg-accent-primary hover:bg-accent-primaryHover
          active:bg-accent-primaryActive`,
        variant === 'secondary' &&
          `border border-foundation-tertiary bg-foundation-inverted
          hover:bg-foundation-primary active:bg-foundation-secondary`,
        variant === 'tertiary' &&
          `border border-foundation-secondary bg-foundation-inverted
          hover:bg-foundation-primary active:bg-foundation-secondary`,
        // NOTE Loading buttons are functionally disabled but not necessarily
        //      visually disabled so we cannot rely on the pseudo-selector.
        disabled &&
          variant !== 'link' &&
          'border-none bg-foundation-disabled text-foundation-disabled',
        className,
      )}
      disabled={variant !== 'link' && (disabled || loading)}
      ref={ref}
      type={type}
      {...props}
    >
      {loading && variant !== 'link' && (
        <div className="absolute inset-0 grid items-center justify-center">
          <Loader />
        </div>
      )}
      {Icon && <Icon className="text" />}
      {text && <div className="text line-clamp-2">{text}</div>}
    </button>
  ),
);

Button.displayName = 'Button';
