import { extendTailwindMerge } from 'tailwind-merge';

import { RADIUS } from '@/core/tamagoshiTailwind/tokens/radius';
import { SPACING } from '@/core/tamagoshiTailwind/tokens/spacing';
import {
  FONT_SIZES,
  FONT_WEIGHTS,
  LINE_HEIGHTS,
} from '@/core/tamagoshiTailwind/tokens/typography';

export const twMerge = extendTailwindMerge({
  override: {
    classGroups: {
      'font-size': [{ text: Object.keys(FONT_SIZES) }],
      'font-weight': [{ font: Object.keys(FONT_WEIGHTS) }],
      leading: [{ leading: Object.keys(LINE_HEIGHTS) }],
    },
    conflictingClassGroups: {
      // NOTE Since we set the font sizes to also set their respective line
      //      height, although discouraged providing a specific leading class
      //      name should overwrite that of the sizing class name.
      //      See https://github.com/dcastil/tailwind-merge/issues/482
      'font-size': [],
    },
    theme: {
      borderRadius: Object.keys(RADIUS),
      spacing: Object.keys(SPACING),
    },
  },
});
