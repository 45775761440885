// istanbul ignore file reason: Dynamic import file
import dynamic from 'next/dynamic';
import React from 'react';

import { useBooleanFlags } from '@/domains/core/flags/flags.hooks';
import { LiveSupportProps } from '@/domains/digitalAdvice/LiveSupport/LiveSupport';

const LiveSupport = dynamic<LiveSupportProps>(
  () =>
    import('@/domains/digitalAdvice/LiveSupport/LiveSupport').then(
      (module) => module.LiveSupport,
    ),
  { ssr: false },
);

export const DynamicLiveSupport: React.FC<LiveSupportProps> = (props) => {
  const [isChatActivated] = useBooleanFlags(['zendeskBotIntegration']);

  return !isChatActivated ? <LiveSupport {...props} /> : null;
};
