import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';

import { makeTamagoshiIcon } from '@/core/tamagoshiTailwind/util/makeTamagoshiComponent';

export const ChevronRightIcon = makeTamagoshiIcon((props, ref) => (
  <Svg
    optimizationId="tama-chevron-right"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.31409 16.1611L12.4805 11.9946L8.31409 7.82819C7.8953 7.4094 7.8953 6.73289 8.31409 6.31409C8.73289 5.8953 9.4094 5.8953 9.82819 6.31409L14.757 11.243C15.1758 11.6617 15.1758 12.3383 14.757 12.757L9.82819 17.6859C9.4094 18.1047 8.73289 18.1047 8.31409 17.6859C7.90604 17.2671 7.8953 16.5799 8.31409 16.1611Z"
      fill="currentColor"
    />
  </Svg>
));

ChevronRightIcon.displayName = 'ChevronRightIcon';
