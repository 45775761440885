import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';

import { makeTamagoshiIcon } from '@/core/tamagoshiTailwind/util/makeTamagoshiComponent';

export const ChevronLeftIcon = makeTamagoshiIcon((props, ref) => (
  <Svg
    optimizationId="tama-chevron-left"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.757 16.1611L10.5906 11.9946L14.757 7.82819C15.1758 7.4094 15.1758 6.73289 14.757 6.31409C14.3383 5.8953 13.6617 5.8953 13.243 6.31409L8.31409 11.243C7.8953 11.6617 7.8953 12.3383 8.31409 12.757L13.243 17.6859C13.6617 18.1047 14.3383 18.1047 14.757 17.6859C15.1651 17.2671 15.1758 16.5799 14.757 16.1611Z"
      fill="currentColor"
    />
  </Svg>
));

ChevronLeftIcon.displayName = 'ChevronLeftIcon';
