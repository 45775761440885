import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';

import { makeTamagoshiLogo } from '@/core/tamagoshiTailwind/util/makeTamagoshiComponent';

export const B2bMulticolorLightHorizontalHeaderLogo = makeTamagoshiLogo(
  (props, ref) => (
    <Svg
      width="223"
      height="30"
      fill="none"
      viewBox="0 0 223 30"
      xmlns="http://www.w3.org/2000/svg"
      optimizationId="tama-b2b-multicolor-light-horizontal"
      {...props}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.99 11.74c0 1.97-.62 3.56-1.76 4.64-1.23 1.1-2.88 1.69-5.05 1.69h-1.34V24H182V6h6.29c2.26 0 4.02.5 5.15 1.48a5.99 5.99 0 0 1 1.55 4.26ZM186.74 14h.93c.72 0 1.34-.2 1.75-.6.41-.39.62-.98.62-1.67 0-1.2-.72-1.78-2.06-1.78h-1.24V14Zm20.4-4.15c-.4-.1-.82-.1-1.23-.1-.72 0-1.44.3-2.17.7a5.51 5.51 0 0 0-1.65 1.77h-.2l-.72-2.18h-3.6V23.9h4.84v-6.82c0-.9.2-1.59.72-2.08.4-.5 1.23-.7 2.26-.7.72 0 1.24 0 1.65.1l.41-4.54h-.3Zm2.79 2.07a6.99 6.99 0 0 1 5.15-1.97c2.06 0 3.81.59 5.15 1.97 1.45 1.29 2.06 2.97 2.06 5.05 0 2.07-.72 3.76-2.06 5.04a7.16 7.16 0 0 1-5.15 1.88c-2.06 0-3.81-.6-5.15-1.88a6.71 6.71 0 0 1-2.06-5.04c0-2.08.62-3.76 2.06-5.05Zm7.93 5.05c0 1.88-1.23 3.06-2.78 3.06-1.55 0-2.78-1.18-2.78-3.06 0-1.78 1.23-3.07 2.78-3.07 1.55 0 2.78 1.19 2.78 3.07Z"
        fill="#FFB950"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m52.67 7.4-4.16 7.43L44.42 6l-5.1 1.36L33 23.83h5.64l3.8-9.71 3.75 9.25h2.88l5.55-9.92V26l5.26-1.78V8.67L52.67 7.4Zm32.24 2.67h.34c3.1 0 4.43 1.68 4.43 4.64v9.15h-5v-8.1c0-.83-.31-1.33-.95-1.33-.58 0-1.76.2-2.48.72v8.7H76.3v-13.5h4.1l.27 1c.82-.51 2.73-1.23 4.24-1.28Zm13.5 0c-2.04 0-3.75.65-5.14 1.98a6.77 6.77 0 0 0-2.07 5.11c0 2.07.7 3.78 2.07 5.09a7.33 7.33 0 0 0 5.16 1.94c2.06 0 3.78-.66 5.14-1.94a6.69 6.69 0 0 0 2.1-5.09c0-2.1-.7-3.8-2.1-5.1-1.39-1.34-3.1-2-5.16-2Zm2.77 7.1c0 1.85-1.22 3.06-2.75 3.06-1.52 0-2.74-1.21-2.74-3.07 0-1.88 1.22-3.14 2.74-3.14 1.53 0 2.74 1.26 2.74 3.14Zm-38.56-2.63v-3.8l.65-.12c3.86-.7 11.18-2.03 11.18 5.02v8.2h-3.8l-.28-.65s-1.34 1-4.2 1c-3.13 0-4.78-2.15-4.78-4.24 0-3.42 3.02-4.24 6.4-4.24h2.38c0-1.38-.84-1.9-2.61-1.87-2.5 0-4.5.56-4.93.7h-.01Zm4.73 6.43c1.86 0 2.85-.7 2.85-2.25v-.15l-2.25-.02c-1.74 0-2.58.26-2.58 1.17 0 .9.74 1.25 1.98 1.25Zm99.27-8.92c1.39-1.33 3.1-1.99 5.14-1.99 2.06 0 3.78.66 5.17 2a6.7 6.7 0 0 1 2.09 5.1c0 2.07-.7 3.78-2.1 5.09a7.23 7.23 0 0 1-5.13 1.94 7.33 7.33 0 0 1-5.17-1.94 6.75 6.75 0 0 1-2.06-5.09c0-2.1.7-3.8 2.06-5.1Zm5.17 8.18c1.52 0 2.74-1.21 2.74-3.07 0-1.88-1.22-3.14-2.74-3.14-1.53 0-2.75 1.26-2.75 3.14 0 1.86 1.22 3.07 2.75 3.07Zm-13.53-10.16a6 6 0 0 1 .35 0c3.1 0 4.43 1.68 4.43 4.64v9.15h-5.01v-8.1c0-.83-.3-1.33-.94-1.33-.59 0-1.76.2-2.48.72v8.7h-4.96v-13.5h4.1l.28 1c.81-.51 2.72-1.23 4.23-1.28ZM126.03 7.4l-4.16 7.44L117.78 6l-5.1 1.36-6.32 16.47h5.63l3.8-9.71 3.75 9.25h2.89l5.54-9.92V26l5.27-1.78V8.67l-7.22-1.28Zm9.94 7.15v-3.8l.65-.12c3.87-.7 11.18-2.03 11.19 5.02v8.2H144l-.28-.65s-1.34 1-4.2 1c-3.13 0-4.78-2.15-4.78-4.24 0-3.42 3.01-4.24 6.4-4.24h2.38c0-1.38-.85-1.9-2.62-1.87a17.82 17.82 0 0 0-4.93.7Zm4.73 6.43c1.87 0 2.85-.7 2.85-2.25v-.15l-2.25-.02c-1.74 0-2.58.26-2.58 1.17 0 .9.74 1.25 1.98 1.25Z"
        fill="#fff"
      />
      <path
        d="M19.73 0 6.86 2.08c-.93.15-1.71.8-2 1.66L.21 15.96a2.5 2.5 0 0 0-.21.93c0 .58.21 1.15.57 1.66l8.22 10.2c.5.65 1.29.94 2 1.01h.43l12.87-2.08c.93-.15 1.71-.8 2.07-1.66l4.65-12.22c.36-.86.21-1.87-.43-2.59l-8.15-10.2C21.66.36 20.94 0 20.16 0h-.43Z"
        fill="url(#tama-b2b-multicolor-light-horizontal)"
      />
      <path
        d="m18.59 8.45-2.91 5.5-2.84-6.51-3.55 1-4.33 12.17h3.9l2.63-7.2 2.56 6.82h1.98l3.9-7.29v9.38L23.57 21V9.38l-4.97-.93Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="tama-b2b-multicolor-light-horizontal"
          x1="5.36"
          y1="23.36"
          x2="22.54"
          y2="9.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6685A" />
          <stop offset="1" stopColor="#FFB950" />
        </linearGradient>
      </defs>
    </Svg>
  ),
);

B2bMulticolorLightHorizontalHeaderLogo.displayName =
  'B2bMulticolorLightHorizontalHeaderLogo';
