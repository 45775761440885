import { forwardRef, type HTMLAttributes, type ReactNode } from 'react';

import {
  FONT_SCALES,
  FONT_WEIGHTS,
} from '@/core/tamagoshiTailwind/tokens/typography';
import { twMerge } from '@/core/tamagoshiTailwind/util/twMerge';

// TODO Make `as` required to encourage users to use the utility components
//      directly.

export type TypographyHeadingProps = {
  /** The HTML element to use for the root node */
  as?: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'span';
  /** The sizing and height of the heading */
  scale?: Extract<(typeof FONT_SCALES)[number], `title${string}`>;
  /** Only support bold titles, optional and allowed for convenience */
  weight?: 'bold';
};

export type TypographyTextProps = {
  /** The HTML element to use for the root node */
  as?: 'div' | 'li' | 'p' | 'span' | 'td' | 'th';
  /** The sizing and height of the text */
  scale?: Exclude<(typeof FONT_SCALES)[number], `title${string}`>;
  /** The weight of the text */
  weight?: keyof typeof FONT_WEIGHTS;
};

export type TypographyProps<
  TKind extends 'heading' | 'text' = 'heading' | 'text',
> = {
  className?: string;
} & (TKind extends 'heading' ? TypographyHeadingProps : unknown) &
  (TKind extends 'text' ? TypographyTextProps : unknown) &
  (TKind extends 'heading' | 'text'
    ? TypographyHeadingProps | TypographyTextProps
    : unknown) &
  Pick<HTMLAttributes<HTMLElement>, 'title'> &
  (
    | { children: ReactNode; dangerouslySetInnerHTML?: never }
    | { children?: never; dangerouslySetInnerHTML: { __html: string } }
  );

/** Map a component tag to the appropriate default properties */
const DEFAULT_SPECIFICATIONS =
  // prettier-ignore
  {
    div:  { scale: 'body1',  weight: 'regular' },
    h1:   { scale: 'title1', weight: 'bold' },
    h2:   { scale: 'title2', weight: 'bold' },
    h3:   { scale: 'title3', weight: 'bold' },
    h4:   { scale: 'title4', weight: 'bold' },
    li:   { scale: 'body1',  weight: 'regular' },
    p:    { scale: 'body1',  weight: 'regular' },
    span: { scale: 'body1',  weight: 'regular' },
    td:   { scale: 'body1',  weight: 'regular' },
    th:   { scale: 'body1',  weight: 'regular' },
  } as const satisfies Record<
    NonNullable<TypographyHeadingProps['as'] | TypographyTextProps['as']>,
    { scale: (typeof FONT_SCALES)[number]; weight: keyof typeof FONT_WEIGHTS }
  >;

/** Map a given font scale to the appropriate class names */
export const SCALE_TO_CLASSES =
  // prettier-ignore
  {
    body1:  'text-body1 leading-body1',
    body2:  'text-body2 leading-body2',
    small:  'text-small leading-small',
    tiny:   'text-tiny leading-tiny',
    title1: 'text-title2 font-bold leading-title2 desktop:text-title1 desktop:leading-title1',
    title2: 'text-title2 font-bold leading-title2',
    title3: 'text-title3 font-bold leading-title3',
    title4: 'text-title4 font-bold leading-title4',
  } as const satisfies Record<(typeof FONT_SCALES)[number], string>;

/** Map a given font weight to the appropriate class names */
export const WEIGHT_TO_CLASSES =
  // prettier-ignore
  {
    bold:     'font-bold',
    regular:  'font-regular',
    semibold: 'font-semibold',
  } as const satisfies Record<keyof typeof FONT_WEIGHTS, string>;

/**
 * Display text using the Tamagoshi typography.
 *
 * ## Headings
 *
 * Headings are always bold. In order to render semantic elements to the DOM,
 * use the appropriately named `H1`, `H2`, `H3` or `H4`. They can be customized
 * to use a different title font size through the `scale` property. The `weight`
 * is not customizable.
 *
 * ## Text
 *
 * The utility components can all be configured with the `scale` property for
 * any non-title font size value while the `weight` property accepts all
 * existing values.
 *
 * ## Base Component
 *
 * If using the non-recommended `Typography` component directly, default values
 * for both the `scale` and `weight` properties are based on the tag provided
 * through the `as` property. The component accepts `className` which will be
 * merged first in order to prevent unwarranted customization. Do not overwrite
 * the above constraints.
 */
export const Typography = forwardRef<any, TypographyProps>(
  ({ as: Tag = 'div', className, scale, weight, ...props }, ref) => {
    const specifications = {
      ...DEFAULT_SPECIFICATIONS[Tag],
      ...(scale && { scale }),
      ...(weight && { weight }),
    };
    const classNames = twMerge(
      // NOTE Add a static selector for further reference
      'typography',
      // NOTE Insert `className` first in order to prevent unwarranted scale and
      //      weight customization. Weight classes have to be inserted before
      //      scale's so that titles are bold no matter what.
      className,
      WEIGHT_TO_CLASSES[specifications.weight],
      SCALE_TO_CLASSES[specifications.scale],
    );
    return <Tag className={classNames} ref={ref} {...props} />;
  },
);

Typography.displayName = 'Typography';

// TODO Remove `as` from all typings below as it does nothing (as intended)

/** Render a block element with the proper default typography */
export const Div = forwardRef<HTMLDivElement, TypographyProps>((props, ref) => (
  <Typography {...props} as="div" ref={ref} />
));

Div.displayName = 'Div';

/** Render a level-1 heading with the proper default typography */
export const H1 = forwardRef<HTMLHeadingElement, TypographyProps<'heading'>>(
  (props, ref) => <Typography {...props} as="h1" ref={ref} />,
);

H1.displayName = 'H1';

/** Render a level-2 heading with the proper default typography */
export const H2 = forwardRef<HTMLHeadingElement, TypographyProps<'heading'>>(
  (props, ref) => <Typography {...props} as="h2" ref={ref} />,
);

H2.displayName = 'H2';

/** Render a level-3 heading with the proper default typography */
export const H3 = forwardRef<HTMLHeadingElement, TypographyProps<'heading'>>(
  (props, ref) => <Typography {...props} as="h3" ref={ref} />,
);

H3.displayName = 'H3';

/** Render a level-4 heading with the proper default typography */
export const H4 = forwardRef<HTMLHeadingElement, TypographyProps<'heading'>>(
  (props, ref) => <Typography {...props} as="h4" ref={ref} />,
);

H4.displayName = 'H4';

/** Render a list item with the proper default typography */
export const Li = forwardRef<HTMLLIElement, TypographyProps<'text'>>(
  (props, ref) => <Typography {...props} as="li" ref={ref} />,
);

Li.displayName = 'Li';

/** Render a paragraph with the proper default typography */
export const P = forwardRef<HTMLParagraphElement, TypographyProps<'text'>>(
  (props, ref) => <Typography {...props} as="p" ref={ref} />,
);

P.displayName = 'P';

/** Render a inline element with the proper default typography */
export const Span = forwardRef<HTMLSpanElement, TypographyProps<'text'>>(
  (props, ref) => <Typography {...props} as="span" ref={ref} />,
);

Span.displayName = 'Span';

type TableCellProps = Partial<
  Pick<HTMLTableCellElement, 'colSpan' | 'headers' | 'rowSpan' | 'scope'>
>;

/** Render a table data cell with the proper default typography */
export const Td = forwardRef<
  HTMLTableCellElement,
  TypographyProps<'text'> & TableCellProps
>((props, ref) => <Typography {...props} as="td" ref={ref} />);

Td.displayName = 'Td';

/** Render a table header cell with the proper default typography */
export const Th = forwardRef<
  HTMLTableCellElement,
  TypographyProps<'text'> & TableCellProps
>((props, ref) => <Typography {...props} as="th" ref={ref} />);

Th.displayName = 'Th';
