export const SPACING = {
  0: '0',
  xs: '4px',
  s: '8px',
  m: '16px',
  l: '24px',
  xl: '32px',
  xxl: '40px',
  xxxl: '48px',
} as const;
